import React from "react";
import styled from "styled-components";
// Assets
import GuaranteedIcon from "../../assets/icons/Services/quality-control.png";
import Save from "../../assets/icons/Services/save-money.png";
import Faster from "../../assets/icons/Services/fast-delivery.png";

export default function ServiceBox({ icon, title, subtitle }) {
  let getIcon;

  switch (icon) {
    case "guaranteed":
      getIcon = GuaranteedIcon;
      break;
    case "save":
      getIcon = Save;
      break;
    case "faster":
      getIcon = Faster;
      break;
    default:
      getIcon = GuaranteedIcon;
      break;
  }

  return (
    <Wrapper className="flex flexColumn">
      <IconStyle>
        <img src={getIcon} alt="seviceIcon" width="64px" />{" "}
      </IconStyle>
      <TitleStyle className="font20 extraBold">{title}</TitleStyle>
      <SubtitleStyle className="font13">{subtitle}</SubtitleStyle>
    </Wrapper>
  );
}

const Wrapper = styled.div`
  width: 100%;
`;
const IconStyle = styled.div`
  @media (max-width: 860px) {
    margin: 0 auto;
  }
`;
const TitleStyle = styled.h2`
  width: 100%;
  max-width: 300px;
  margin: 0 auto;
  padding: 40px 0;
  @media (max-width: 860px) {
    padding: 20px 0;
  }
`;
const SubtitleStyle = styled.p`
  width: 100%;
  max-width: 300px;
  margin: 0 auto;
`;
