import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { Link } from "react-scroll";
import { useTranslation } from "react-i18next";
import i18next from "i18next";

import { NAVIGATION_LIST } from "../../constants/navigationList";
import Sidebar from "../Nav/Sidebar";
import Backdrop from "../Elements/Backdrop";
import logoImg from "../../assets/icons/logo.svg";
import BurgerIcon from "../../assets/svg/BurgerIcon";

export default function TopNavbar() {
  const { t } = useTranslation();
  const { t: translateNavbar } = useTranslation("translation", {
    keyPrefix: "navbar",
  });

  const [y, setY] = useState(window.scrollY);
  const [sidebarOpen, toggleSidebar] = useState(false);

  useEffect(() => {
    window.addEventListener("scroll", () => setY(window.scrollY));
    return () => {
      window.removeEventListener("scroll", () => setY(window.scrollY));
    };
  }, [y]);

  const changeLang = () => {
    i18next.language === "en"
      ? i18next.changeLanguage("ar")
      : i18next.changeLanguage("en");
  };

  return (
    <>
      <Sidebar sidebarOpen={sidebarOpen} toggleSidebar={toggleSidebar} />
      {sidebarOpen && <Backdrop toggleSidebar={toggleSidebar} />}
      <Wrapper
        className="flexCenter animate darkBg"
        style={y > 100 ? { height: "60px" } : { height: "80px" }}
      >
        <NavInner className="container flexSpaceCenter">
          <Link className="pointer flexNullCenter" to="home" smooth={true}>
            <img src={logoImg} alt="mtorLogo" width="100px" />
          </Link>
          <BurderWrapper
            className="pointer"
            onClick={() => toggleSidebar(!sidebarOpen)}
          >
            <BurgerIcon />
          </BurderWrapper>
          <UlWrapper className="flexNullCenter">
            {NAVIGATION_LIST.map((listItem) => (
              <li className="semiBold font15 pointer" key={listItem.to}>
                <Link
                  activeClass="active"
                  style={{ padding: "10px 15px" }}
                  to={listItem.to}
                  spy={true}
                  smooth={true}
                  offset={-80}
                >
                  {translateNavbar(listItem.name)}
                </Link>
              </li>
            ))}
          </UlWrapper>

          <UlWrapperRight className="flexNullCenter">
            <li className="semiBold font15 pointer" onClick={changeLang}>
              {t("language")}
            </li>
          </UlWrapperRight>
        </NavInner>
      </Wrapper>
    </>
  );
}

const Wrapper = styled(({ sidebarOpen, y, ...rest }) => (
  <nav {...rest} />
)).attrs(() => ({
  // Add any other necessary attributes here
}))`
  width: 100%;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 999;
`;

const NavInner = styled.div`
  position: relative;
  height: 100%;
`;
const BurderWrapper = styled.button`
  outline: none;
  border: 0px;
  background-color: transparent;
  height: 100%;
  padding: 0 15px;
  display: block;

  @media (min-width: 760px) {
    display: none;
  }
`;

const UlWrapper = styled.ul`
  display: flex;
  @media (max-width: 760px) {
    display: none;
  }
`;
const UlWrapperRight = styled.ul`
  color: #fff;

  @media (max-width: 760px) {
    display: none;
  }
`;
