import React from "react";
import { ThemeProvider } from "styled-components";
import i18next from "i18next";

import TopNavbar from "../components/Nav/TopNavbar";
import Header from "../components/Sections/Header";
import Services from "../components/Sections/Services";
import Projects from "../components/Sections/Projects";
import Contact from "../components/Sections/Contact";
import Footer from "../components/Sections/Footer";

export default function Landing() {
  const dirLang = i18next.dir();

  const theme = {
    direction: dirLang,
  };

  return (
    <ThemeProvider theme={theme}>
      <TopNavbar />
      <Header />
      <Services />
      <Projects />
      <Contact />
      <Footer />
    </ThemeProvider>
  );
}
