import React from "react";
import styled from "styled-components";
import { useTranslation } from "react-i18next";

import ProjectBox from "../Elements/ProjectBox";
import serviceCenter from "../../assets/img/service-center-2.jpg";

export default function Projects() {
  const { t } = useTranslation("translation", {
    keyPrefix: "products",
  });

  const products = [
    {
      key: 1,
      title: t("mechanic_app_title"),
      text: t("mechanic_app_text"),
      linkType: "mechanic",
    },
    {
      key: 2,
      title: t("supplier_app_title"),
      text: t("supplier_app_text"),
      linkType: "supplier",
    },
  ];

  return (
    <Wrapper id="products">
      <div className="whiteBg">
        <div className="container">
          <HeaderInfo>
            <h1 className="font40 extraBold">{t("title")}</h1>
            <p className="font13">{t("subtitle")}</p>
          </HeaderInfo>
          <div className="row textCenter">
            {products.map((product) => (
              <div
                className="col-xs-12 col-sm-12 col-md-12 col-lg-12"
                key={product.key}
              >
                <ProjectBox
                  title={product.title}
                  text={product.text}
                  linkType={product.linkType}
                />
              </div>
            ))}
          </div>
        </div>
      </div>

      <div style={{ marginTop: "30px" }}>
        <FullWidthImageContainer>
          <FullWidthImage src={serviceCenter} alt="Company Image" />
        </FullWidthImageContainer>
      </div>
    </Wrapper>
  );
}

const Wrapper = styled.section`
  width: 100%;
`;
const HeaderInfo = styled.div`
  @media (max-width: 860px) {
    text-align: center;
  }
`;

const FullWidthImageContainer = styled.div`
  width: 100vw;
  max-height: 700px;
  overflow: hidden;
  background: url(${serviceCenter}) center center no-repeat;
  background-size: cover;
`;
const FullWidthImage = styled.img`
  width: 100%;
  height: auto;
  border-radius: 0; /* Remove border-radius for sharp corners */
`;
