import React from "react";
import Slider from "react-slick";
import styled from "styled-components";
// Assets
import ClientLogo01 from "../../assets/icons/brands/logo01.svg";
import ClientLogo02 from "../../assets/icons/brands/logo02.svg";
import ClientLogo03 from "../../assets/icons/brands/logo03.svg";
import ClientLogo04 from "../../assets/icons/brands/logo04.svg";
import ClientLogo05 from "../../assets/icons/brands/logo05.svg";
import ClientLogo06 from "../../assets/icons/brands/logo06.svg";
import ClientLogo07 from "../../assets/icons/brands/logo07.svg";

export default function ClientSlider() {
  const ClientLogos = [
    ClientLogo01,
    ClientLogo02,
    ClientLogo03,
    ClientLogo04,
    ClientLogo05,
    ClientLogo06,
    ClientLogo07,
  ];

  const settings = {
    infinite: true,
    speed: 500,
    slidesToShow: 7,
    slidesToScroll: 2,
    arrows: false,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 4,
          slidesToScroll: 2,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
    ],
  };
  return (
    <div>
      <Slider {...settings}>
        {ClientLogos.map((logo) => (
          <LogoWrapper className="flexCenter" key={logo}>
            <ImgStyle src={logo} alt="client logo" />
          </LogoWrapper>
        ))}
      </Slider>
    </div>
  );
}

const LogoWrapper = styled.div`
  width: 100%;
  height: 100px;
  cursor: pointer;
  :focus-visible {
    outline: none;
    border: 0px;
  }
`;
const ImgStyle = styled.img`
  width: 100%;
  height: 100%;
  padding: 10%;
`;
