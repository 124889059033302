export const PRODUCTS_LINKS = {
  mechanicAndroid:
    "https://play.google.com/store/apps/details?id=mtor.mtor_mechanicapp&pcampaignid=pcampaignidMKT-Other-global-all-co-prtnr-py-PartBadge-Mar2515-1",
  mechanicIos:
    "https://apps.apple.com/eg/app/mtor-%D9%85%D8%B1%D9%83%D8%B2-%D8%AE%D8%AF%D9%85%D8%A9/id1643559485",
  mechanicWebsite: "https://service-center.mtor.online/",
  supplierAndroid:
    "https://play.google.com/store/apps/details?id=mtor.mtor_supplierapp",
  supplierIos:
    "https://apps.apple.com/eg/app/mtor-%D9%85%D9%88%D8%B1%D8%AF/id1638941416",
  supplierWebsite: "https://supplier.mtor.online/",
};
