import React from "react";
import styled from "styled-components";

import { PRODUCTS_LINKS } from "../../constants/productsLinks";
import BrowserIcon from "../../assets/svg/BrowserIcon";
import googlePlayAppStore from "../../assets/img/google-play-app-store.png";

export default function ProjectBox({ title, text, linkType }) {
  return (
    <Wrapper>
      <h3 className="font20 extraBold">{title}</h3>
      <p className="font13">{text}</p>

      <QuickLinks>
        <a
          href={PRODUCTS_LINKS[`${linkType}Android`]}
          target="_blank"
          rel="noreferrer"
        >
          <img
            alt="Get it on Google Play"
            src={googlePlayAppStore}
            width="140px"
            height="50px"
          />
        </a>
        <a
          href={PRODUCTS_LINKS[`${linkType}Ios`]}
          target="_blank"
          rel="noreferrer"
        >
          <img
            src="https://developer.apple.com/assets/elements/badges/download-on-the-app-store.svg"
            alt="app store download"
            width="140px"
            height="50px"
          />
        </a>

        <Button
          href={PRODUCTS_LINKS[`${linkType}Website`]}
          target="_blank"
          rel="noreferrer"
          dir="ltr"
        >
          <IconContainer>
            <BrowserIcon />
          </IconContainer>
          <span className="whiteColor font18 semiBold"> Website</span>
        </Button>
      </QuickLinks>
    </Wrapper>
  );
}

const Wrapper = styled.div`
  margin: auto;
  width: 70%;
  margin-top: 40px;
  border: 2px solid #000;
  border-radius: 12px;

  h3 {
    padding-top: 10px;
  }

  p {
    padding-bottom: 20px;
    margin: auto;
    width: 85%;
  }
`;

const QuickLinks = styled.div`
  display: flex;
  justify-content: center;
  flex-wrap: wrap;

  a {
    margin: 10px;
  }
`;

const Button = styled.a`
  width: 140px;
  height: 50px;
  padding-top: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  text-decoration: none;
  background-color: #000;
  color: #fff;
  border: 1px solid #fff;
  border-radius: 8px;
  cursor: pointer;
  transition: background-color 0.3s;
`;

const IconContainer = styled.span`
  margin-right: 6px;
`;
