import React from "react";
import styled from "styled-components";
import { useTranslation } from "react-i18next";

import ClientSlider from "../Elements/ClientSlider";
import ServiceBox from "../Elements/ServiceBox";
import serviceCenter from "../../assets/img/service-center.jpg";

export default function Services() {
  const { t } = useTranslation("translation", {
    keyPrefix: "services",
  });

  return (
    <Wrapper id="brands">
      <div className="lightBg" style={{ padding: "60px 0" }}>
        <div className="container">
          <ClientSlider />
        </div>
      </div>
      <div className="whiteBg" style={{ padding: "60px 0" }}>
        <div className="container">
          <HeaderInfo>
            <h1 className="font40 extraBold">{t("title")}</h1>
            <p className="font13 flexWrap">{t("subtitle")}</p>
          </HeaderInfo>
          <ServiceBoxRow className="flex">
            <ServiceBoxWrapper>
              <ServiceBox
                icon="guaranteed"
                title={t("guaranteed")}
                subtitle={t("guaranteed_subtitle")}
              />
            </ServiceBoxWrapper>
            <ServiceBoxWrapper>
              <ServiceBox
                icon="save"
                title={t("save")}
                subtitle={t("save_subtitle")}
              />
            </ServiceBoxWrapper>
            <ServiceBoxWrapper>
              <ServiceBox
                icon="faster"
                title={t("faster")}
                subtitle={t("faster_subtitle")}
              />
            </ServiceBoxWrapper>
          </ServiceBoxRow>
        </div>

        <FullWidthImageContainer>
          <FullWidthImage src={serviceCenter} alt="service center image" />
        </FullWidthImageContainer>
      </div>
    </Wrapper>
  );
}

const Wrapper = styled.section`
  width: 100%;
`;
const ServiceBoxRow = styled.div`
  @media (max-width: 860px) {
    flex-direction: column;
  }
`;
const ServiceBoxWrapper = styled.div`
  width: calc(100% / 3);
  margin-right: 5%;
  padding: 80px 0;
  @media (max-width: 860px) {
    width: 100%;
    text-align: center;
    padding: 40px 0;
  }
`;
const HeaderInfo = styled.div`
  @media (max-width: 860px) {
    text-align: center;
  }
`;
const FullWidthImageContainer = styled.div`
  width: 100vw;
  max-height: 600px;
  overflow: hidden;
  background: url(${serviceCenter}) center center no-repeat;
  background-size: cover;
`;
const FullWidthImage = styled.img`
  width: 100%;
  height: auto;
  border-radius: 0; /* Remove border-radius for sharp corners */
`;
