import React from "react";
import styled from "styled-components";
import { useTranslation } from "react-i18next";

import logoImg from "../../assets/icons/logo.svg";
import linkedin from "../../assets/icons/linkedin.png";
import BrowserIcon from "../../assets/svg/BrowserIcon";
import googlePlayAppStore from "../../assets/img/google-play-app-store.png";
import { PRODUCTS_LINKS } from "../../constants/productsLinks";

export default function Footer() {
  const { t } = useTranslation("translation", {
    keyPrefix: "footer",
  });

  const links = [
    {
      key: 1,
      title: t("mechanic_links"),
      linkType: "mechanic",
    },
    {
      key: 2,
      title: t("supplier_links"),
      linkType: "supplier",
    },
  ];

  return (
    <Wrapper>
      <FooterWrap className="darkBg">
        <div className="container">
          <InnerWrapper style={{ padding: "30px 0" }}>
            <Column>
              <img src={logoImg} alt="mtorLogo" width="100px" />
            </Column>

            {links.map((link) => (
              <Column key={link.key}>
                <QuickLinks>
                  <h4>{link.title} </h4>
                  <a
                    href={PRODUCTS_LINKS[`${link.linkType}Android`]}
                    target="_blank"
                    rel="noreferrer"
                  >
                    <img
                      alt="Get it on Google Play"
                      src={googlePlayAppStore}
                      width="160px"
                    />
                  </a>
                  <a
                    href={PRODUCTS_LINKS[`${link.linkType}Ios`]}
                    target="_blank"
                    rel="noreferrer"
                  >
                    <img
                      src="https://developer.apple.com/assets/elements/badges/download-on-the-app-store.svg"
                      alt="app store download"
                      width="160px"
                    />
                  </a>

                  <Button
                    href={PRODUCTS_LINKS[`${link.linkType}Website`]}
                    target="_blank"
                    rel="noreferrer"
                    dir="ltr"
                  >
                    <IconContainer>
                      <BrowserIcon />
                    </IconContainer>
                    <span className="whiteColor font18 semiBold"> Website</span>
                  </Button>
                </QuickLinks>
              </Column>
            ))}

            {/* <Column>
              <QuickLinks>
                <h4> {t("supplier_links")} </h4>
                <a
                  href="https://play.google.com/store/apps/details?id=mtor.mtor_supplierapp"
                  target="_blank"
                  rel="noreferrer"
                >
                  <img
                    alt="Get it on Google Play"
                    src={googlePlayAppStore}
                    width="160px"
                  />
                </a>
                <a
                  href="https://apps.apple.com/eg/app/mtor-%D9%85%D9%88%D8%B1%D8%AF/id1638941416"
                  target="_blank"
                  rel="noreferrer"
                >
                  <img
                    src="https://developer.apple.com/assets/elements/badges/download-on-the-app-store.svg"
                    alt="app store download"
                    width="160px"
                  />
                </a>

                <Button
                  href="https://supplier.mtor.online/"
                  target="_blank"
                  rel="noreferrer"
                  dir="ltr"
                >
                  <IconContainer>
                    <BrowserIcon />
                  </IconContainer>
                  <span className="whiteColor font18 semiBold"> Website</span>
                </Button>
              </QuickLinks>
            </Column> */}

            <Column>
              <ContactUs>
                <h4> {t("contact_us")} </h4>
                <p>careers@mtor.co</p>
              </ContactUs>
            </Column>
          </InnerWrapper>

          <BottomRow>
            <CopyrightText className="whiteColor font13">
              © {t("copyright_mtor")} | {t("rights_reserved")}
            </CopyrightText>

            <a
              href="https://www.linkedin.com/company/mtor-e-commerce/mycompany/"
              target="_blank"
              rel="noreferrer"
            >
              <img src={linkedin} alt="linkedin" />
            </a>
          </BottomRow>
        </div>
      </FooterWrap>
    </Wrapper>
  );
}

const Wrapper = styled.div`
  width: 100%;
`;

const FooterWrap = styled.div`
  color: #fff;
  padding-block: 25px;
`;

const InnerWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: start;

  @media (max-width: 550px) {
    flex-direction: column;
    justify-content: start;
  }
`;

const Column = styled.div`
  flex: 1;

  @media (max-width: 550px) {
    margin: 20px 0;
  }
`;

const QuickLinks = styled.div`
  display: flex;
  flex-direction: column;
  a {
    margin: 10px 0;
  }
`;

const ContactUs = styled.div`
  p {
    margin: 10px 0;
  }
`;

const BottomRow = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: start;
  margin-top: 15px;

  @media (max-width: 550px) {
    flex-direction: column;
    align-items: center;
  }

  a {
    @media (max-width: 550px) {
      margin-top: 12px;
    }
  }
`;

const CopyrightText = styled.p``;

const Button = styled.a`
  width: 160px;
  height: 55px;
  padding-top: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  text-decoration: none;
  background-color: #000;
  color: #fff;
  border: 1px solid #fff;
  border-radius: 8px;
  cursor: pointer;
  transition: background-color 0.3s;
`;

const IconContainer = styled.span`
  margin-right: 6px;
`;
