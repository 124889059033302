import React from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";

export default function Contact() {
  const { t } = useTranslation("translation", {
    keyPrefix: "careers",
  });

  return (
    <Wrapper id="careers">
      <div className="container">
        <HeaderText>{t("title")}</HeaderText>
        <ButtonWrapper>
          <ExternalLink
            href="https://www.linkedin.com/company/mtor-e-commerce/jobs/"
            target="_blank"
          >
            {t("button_action")}
          </ExternalLink>
        </ButtonWrapper>
      </div>
    </Wrapper>
  );
}

const Wrapper = styled.section`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 400px;
  text-align: center;
`;

const HeaderText = styled.h2`
  font-size: 24px;
  font-weight: bold;
  margin-bottom: 20px;
`;

const ButtonWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const ExternalLink = styled.a`
  display: inline-block;
  padding: 10px 20px;
  background-color: #007bff;
  color: #fff;
  text-decoration: none;
  border-radius: 5px;
  cursor: pointer;
  margin-top: 10px;

  &:hover {
    opacity: 0.8;
  }
`;
