import React, { useState, useEffect } from "react";
import styled from "styled-components";

import upArrow from "../../assets/icons/up-arrow.png";

const ToTopButton = () => {
  const [isVisible, setIsVisible] = useState(false);

  const handleScroll = () => {
    const scrollTop =
      document.documentElement.scrollTop || document.body.scrollTop;
    setIsVisible(scrollTop > 150);
  };

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <TopButtonContainer data-isvisible={isVisible} onClick={scrollToTop}>
      <img src={upArrow} alt="upArrow" />
    </TopButtonContainer>
  );
};

const TopButtonContainer = styled.a`
  display: ${(props) => (props["data-isvisible"] ? "block" : "none")};
  position: fixed;
  bottom: 0px;
  right: 50px;
  background-color: #333;
  color: #fff;
  padding-top: 15px;
  border-radius: 5px;
  cursor: pointer;
  text-align: center;
  z-index: 1000;
  width: 48px;

  &:hover {
    background-color: #3974ba;
    color: #fff;
  }
`;

export default ToTopButton;
