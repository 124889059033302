import React from "react";
import styled from "styled-components";
import { useTranslation } from "react-i18next";

import HeaderImage from "../../assets/img/header-image.jpg";
import Dots from "../../assets/svg/Dots";
import googlePlayAppStore from "../../assets/img/google-play-app-store.png";
import { PRODUCTS_LINKS } from "../../constants/productsLinks";

export default function Header() {
  const { t } = useTranslation("translation", {
    keyPrefix: "header",
  });

  return (
    <Wrapper id="home" className="container flexSpaceCenter">
      <LeftSide className="flexCenter">
        <div>
          <h1 className="extraBold font40"> {t("title")} </h1>
          <HeaderP className="font18 semiBold">{t("vision")}</HeaderP>
          <BtnWrapper>
            <a
              href={PRODUCTS_LINKS["mechanicAndroid"]}
              target="_blank"
              rel="noreferrer"
            >
              <img
                alt="Get it on Google Play"
                src={googlePlayAppStore}
                width="180px"
                height="60px"
              />
            </a>
            <a
              href={PRODUCTS_LINKS["mechanicIos"]}
              target="_blank"
              rel="noreferrer"
            >
              <img
                src="https://developer.apple.com/assets/elements/badges/download-on-the-app-store.svg"
                alt="app store download"
                width="180px"
              />
            </a>
          </BtnWrapper>
        </div>
      </LeftSide>
      <RightSide>
        <ImageWrapper>
          <Img
            className="radius8"
            src={HeaderImage}
            alt="office"
            style={{ zIndex: 9 }}
          />

          <DotsWrapper>
            <Dots />
          </DotsWrapper>
        </ImageWrapper>
        <GreyDiv className="lightBg"></GreyDiv>
      </RightSide>
    </Wrapper>
  );
}

const Wrapper = styled.section`
  padding-top: 80px;
  width: 100%;
  min-height: 840px;
  @media (max-width: 960px) {
    flex-direction: column;
  }
  direction: ${(props) => props.theme.direction}; // Add this line
`;

const LeftSide = styled.div`
  width: 45%;
  height: 100%;

  @media (max-width: 960px) {
    width: 100%;
    order: 2;
    margin: 50px 0;
    text-align: center;
  }
  @media (max-width: 560px) {
    margin: 80px 0 50px 0;
  }
  flex-direction: ${(props) =>
    props.theme.direction === "rtl" ? "row-reverse" : "row"}; // Add this line
`;

const RightSide = styled.div`
  width: 50%;
  height: 100%;
  @media (max-width: 960px) {
    width: 100%;
    order: 1;
    margin-top: 30px;
  }
  flex-direction: ${(props) =>
    props.theme.direction === "rtl" ? "row-reverse" : "row"}; // Add this line
`;

const HeaderP = styled.div`
  max-width: 470px;
  padding: 15px 0 50px 0;
  line-height: 1.5rem;
  @media (max-width: 960px) {
    padding: 15px 0 50px 0;
    text-align: center;
    max-width: 100%;
  }
`;
const BtnWrapper = styled.div`
  display: flex;
  justify-content: between;
  flex-wrap: wrap;
  gap: 15px;
  @media (max-width: 960px) {
    margin: 0 auto;
  }
`;
const GreyDiv = styled.div`
  width: 30%;
  height: 700px;
  position: absolute;
  top: 0;
  ${(props) => (props.theme.direction === "rtl" ? "left" : "right")}: 0;
  z-index: 0;
  @media (max-width: 960px) {
    display: none;
  }
`;

const ImageWrapper = styled.div`
  display: flex;
  justify-content: ${(props) =>
    props.theme.direction === "rtl" ? "flex-start" : "flex-end"};
  position: relative;
  z-index: 9;

  @media (max-width: 960px) {
    width: 100%;
    justify-content: center;
  }
`;

const Img = styled.img`
  @media (max-width: 560px) {
    width: 80%;
    height: auto;
  }
`;

const DotsWrapper = styled.div`
  position: absolute;
  ${(props) => (props.theme.direction === "rtl" ? "left" : "right")}: -100px;
  bottom: 100px;
  z-index: 2;
  @media (max-width: 960px) {
    ${(props) => (props.theme.direction === "rtl" ? "left" : "right")}: 100px;
  }
  @media (max-width: 560px) {
    display: none;
  }
`;
