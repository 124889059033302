import React from "react";
import styled from "styled-components";
import { Link } from "react-scroll";
import { useTranslation } from "react-i18next";
import i18next from "i18next";

import CloseIcon from "../../assets/svg/CloseIcon";
import logoImg from "../../assets/icons/logo.svg";
import { NAVIGATION_LIST } from "../../constants/navigationList";

export default function Sidebar({ sidebarOpen, toggleSidebar }) {
  const { t } = useTranslation();
  const { t: translateNavbar } = useTranslation("translation", {
    keyPrefix: "navbar",
  });

  const changeLang = () => {
    i18next.language === "en"
      ? i18next.changeLanguage("ar")
      : i18next.changeLanguage("en");
  };

  return (
    <Wrapper className="animate darkBg" sidebarOpen={sidebarOpen}>
      <SidebarHeader className="flexSpaceCenter">
        <div className="flexNullCenter">
          <img src={logoImg} alt="mtorLogo" width="100px" />
        </div>
        <CloseBtn
          onClick={() => toggleSidebar(!sidebarOpen)}
          className="animate pointer"
        >
          <CloseIcon />
        </CloseBtn>
      </SidebarHeader>

      <UlStyle className="flexNullCenter flexColumn">
        {NAVIGATION_LIST.map((listItem) => (
          <li className="semiBold font15 pointer" key={listItem.to}>
            <Link
              onClick={() => toggleSidebar(!sidebarOpen)}
              activeClass="active"
              className="whiteColor"
              style={{ padding: "10px 15px" }}
              to={listItem.to}
              spy={true}
              smooth={true}
              offset={-60}
            >
              {translateNavbar(listItem.name)}
            </Link>
          </li>
        ))}

        <li className="semiBold font15 pointer whiteColor" onClick={changeLang}>
          {t("language")}
        </li>
      </UlStyle>
    </Wrapper>
  );
}

const Wrapper = styled(({ sidebarOpen, ...rest }) => <nav {...rest} />).attrs(
  () => ({
    // Add any other necessary attributes here
  })
)`
  width: 400px;
  height: 100vh;
  position: fixed;
  top: 0;
  padding: 0 30px;
  right: ${(props) => (props.sidebarOpen ? "0px" : "-400px")};
  z-index: 9999;
  @media (max-width: 400px) {
    width: 100%;
  }
`;
const SidebarHeader = styled.div`
  padding: 20px 0;
`;
const CloseBtn = styled.button`
  border: 0px;
  outline: none;
  background-color: transparent;
  padding: 10px;
`;
const UlStyle = styled.ul`
  padding: 40px;
  li {
    margin: 20px 0;
  }
`;
